import React from "react";

const NotFound: React.FC = () => {
    return (
        <div className="not-found">
            <h1>:( 404 Not Found</h1>
        </div>
    );
}

export default NotFound;
